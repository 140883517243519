body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

.shape-pattern-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.shape-pattern-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.shape-pattern-controls {
  width: 250px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
}

.control-scroll {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.title {
  text-align: center;
  color: #333;
  margin: 0 0 10px 0;
  font-size: 1.5em;
}

.svg-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
}

.svg-container svg {
  max-width: 100%;
  max-height: 100%;
}

.control-group {
  margin-bottom: 15px;
}

.control-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  font-size: 12px;
}

.select,
.text-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 14px;
  color: #333;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #333;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #333;
  cursor: pointer;
}

.color-controls {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.color-control {
  flex: 1;
}

.color-input {
  -webkit-appearance: none;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  border: 1px solid #ddd;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-switch:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-switch {
  background-color: #333;
}

input:checked + .slider-switch:before {
  transform: translateX(26px);
}

.switch-label {
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.button {
  flex: 1;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .shape-pattern-container {
    flex-direction: column;
  }

  .shape-pattern-controls {
    width: 100%;
    height: 40vh;
  }

  .shape-pattern-view {
    height: 60vh;
  }
}
